import {
    GET_PURCHASE_ORDER, 
    ADD_PURCHASE_ORDER_START, 
    ADD_PURCHASE_ORDER_SUCCESS, 
    ADD_PURCHASE_ORDER_FAIL, 
    GET_PURCHASE_ORDER_DETAIL, 
    ADD_PURCHASE_ORDER_DETAIL,
    DELETE_PURCHASE_ORDER_DETAIL,
    UPDATE_PURCHASE_ORDER_DONE,
    GET_PURCHASE_ORDER_END,
    GET_PURCHASE_ORDER_DETAIL_START
} from '../actions/types'
import {updateObject} from "../shared/utility";
import * as actionTypes from "../actions/types";

const initialState = {
    purchaseOrder: {},
    purchaseOrders : null,
    purchaseOrderDetail : {},
    purchaseOrderDetails : null,
    updatePurchaseOrderDone : null,
    loadingUpdatePurchaseOrderDone : true,
    loading: false,
    loadingAddPurchaseOrder: false,
    loadingPurchaseOrderDetail: false,
    counting: 0,
    error: {},
    purchaseOrdersV2: {},
    purchaseOrderV2: {},
    loadingUploadImage: false,
    loadingUploadDocument: false,
    errorImage: null,
    errorDocument: null,
    urlImage: [],
    urlDocument: [],
    products: {},
    round: null
}

const fetchPurchaseOrdersStart = (state, action) => {
    return updateObject(state, { error: {}, loading: true })
}

const fetchPurchaseOrdersSuccess = (state, action) => {
    return updateObject(state, {
        purchaseOrdersV2: action.purchase_orders,
        loading: false
    })
}

const fetchPurchaseOrdersFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false })
}

const fetchPurchaseOrderProductsStart = (state, action) => {
    return updateObject(state, { error: {}, loading: true })
}

const fetchPurchaseOrderProductsSuccess = (state, action) => {
    return updateObject(state, {
        products: action.products,
        round: action.round,
        loading: false
    })
}

const fetchPurchaseOrderProductsFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false })
}

const storePurchaseOrderStart = (state, action) => {
    return updateObject(state, { error: {}, loading: true })
}

const storePurchaseOrderSuccess = (state, action) => {
    return updateObject(state, {
        counting: state.counting + 1,
        loading: false,
    })
}

const storePurchaseOrderFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false })
}

const getPurchaseOrderStart = (state, action) => {
    return updateObject(state, { error: {}, loading: true })
}

const getPurchaseOrderSuccess = (state, action) => {
    return updateObject(state, {
        purchaseOrderV2: action.purchase_order,
        loading: false
    })
}

const getPurchaseOrderFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false })
}

const storePurchaseOrderFileStart = (state, action) => {
    return updateObject(state, { loadingUploadImage: true })
}

const storePurchaseOrderFileSuccess = (state, action) => {
    const obj = [...state.urlImage, {link: action.url, mime_type: action.extension, caption: action.caption}]
    return updateObject(state, {
        urlImage: obj,
        loadingUploadImage: false
    })
}

const storePurchaseOrderFileFail = (state, action) => {
    return updateObject(state, {
        errorImage: action.error,
        loadingUploadImage: false
    })
}

const uploadDocumentPurchaseOrderStart = (state, action) => {
    return updateObject(state, { loadingUploadDocument: true })
}

const uploadDocumentPurchaseOrderSuccess = (state, action) => {
    const obj = [{link: action.url, mime_type: action.extension, caption: action.caption}]
    return updateObject(state, {
        urlDocument: obj,
        loadingUploadDocument: false
    })
}

const uploadDocumentPurchaseOrderFail = (state, action) => {
    return updateObject(state, {
        errorDocument: action.error,
        loadingUploadDocument: false
    })
}

const deleteImagePORedux = (state, action) => {
    const updatedArray = state.urlImage.filter((a, index) => +index !== +action.index)
    return updateObject(state, {
        loading: false,
        urlImage: updatedArray
    })
}

const deleteDocumentPORedux = (state, action) => {
    const updatedArray = state.urlDocument.filter((a, index) => +index !== +action.index)
    return updateObject(state, {
        loading: false,
        urlDocument: updatedArray
    })
}

const storePurchaseOrderReceiptStart = (state, action) => {
    return updateObject(state, { error: {}, loading: true })
}

const storePurchaseOrderReceiptSuccess = (state, action) => {
    return updateObject(state, {
        counting: state.counting + 1,
        loading: false,
    })
}

const storePurchaseOrderReceiptFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false })
}

const clearFilePoRedux = (state, action) => {
    return updateObject(state, {
        urlDocument: [],
        urlImage: []
    })
}

const downloadPurchaseOrdersStart = (state, action) => {
    return updateObject(state, { error: {}, loading: true })
}

const downloadPurchaseOrdersSuccess = (state, action) => {
    return updateObject(state, {
        // purchaseOrdersV2: action.purchase_orders,
        loading: false
    })
}

const downloadPurchaseOrdersFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false })
}

const downloadPurchaseOrdersOldStart = (state, action) => {
    return updateObject(state, { error: {}, loading: true })
}

const downloadPurchaseOrdersOldSuccess = (state, action) => {
    return updateObject(state, {
        // purchaseOrdersV2: action.purchase_orders,
        loading: false
    })
}

const downloadPurchaseOrdersOldFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false })
}

const reducer = function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_PURCHASE_ORDER:
            return {
                ...state,
                // purchaseOrders: payload,
                loading: true
            }
        case GET_PURCHASE_ORDER_END:
            return {
                ...state,
                purchaseOrders: payload,
                loading: false
            }
        case GET_PURCHASE_ORDER_DETAIL_START:
            return {
                ...state,
                loadingPurchaseOrderDetail: true
            }
        case GET_PURCHASE_ORDER_DETAIL:
            return {
                ...state,
                purchaseOrderDetails: payload,
                loadingPurchaseOrderDetail: false
            }
        case ADD_PURCHASE_ORDER_START:
            return {
                ...state,
                loadingAddPurchaseOrder: true
            }
        case ADD_PURCHASE_ORDER_SUCCESS:
            return {
                ...state,
                purchaseOrder: payload,
                loadingAddPurchaseOrder: false
            }
        case ADD_PURCHASE_ORDER_FAIL:
            return {
                ...state,
                error: payload,
                loadingAddPurchaseOrder: false
            }
        case ADD_PURCHASE_ORDER_DETAIL:
            return {
                ...state,
                purchaseOrderDetail: payload,
                loading: false,
                counting: initialState.counting += 1
            }
        case DELETE_PURCHASE_ORDER_DETAIL:
            return {
                ...state,
                purchaseOrderDetail : payload,
                loading: false,
                counting: initialState.counting -= 1
            }
        case UPDATE_PURCHASE_ORDER_DONE:
            return {
                ...state,
                updatePurchaseOrderDone : payload,
                loadingUpdatePurchaseOrderDone: false,
            }
        case actionTypes.FETCH_PURCHASE_ORDERS_START: return fetchPurchaseOrdersStart(state, action)
        case actionTypes.FETCH_PURCHASE_ORDERS_SUCCESS: return fetchPurchaseOrdersSuccess(state, action)
        case actionTypes.FETCH_PURCHASE_ORDERS_FAIL: return fetchPurchaseOrdersFail(state, action)
        case actionTypes.FETCH_PURCHASE_ORDER_PRODUCTS_START: return fetchPurchaseOrderProductsStart(state, action)
        case actionTypes.FETCH_PURCHASE_ORDER_PRODUCTS_SUCCESS: return fetchPurchaseOrderProductsSuccess(state, action)
        case actionTypes.FETCH_PURCHASE_ORDER_PRODUCTS_FAIL: return fetchPurchaseOrderProductsFail(state, action)
        case actionTypes.STORE_PURCHASE_ORDER_START: return storePurchaseOrderStart(state, action)
        case actionTypes.STORE_PURCHASE_ORDER_SUCCESS: return storePurchaseOrderSuccess(state, action)
        case actionTypes.STORE_PURCHASE_ORDER_FAIL: return storePurchaseOrderFail(state, action)
        case actionTypes.GET_PURCHASE_ORDER_START: return getPurchaseOrderStart(state, action)
        case actionTypes.GET_PURCHASE_ORDER_SUCCESS: return getPurchaseOrderSuccess(state, action)
        case actionTypes.GET_PURCHASE_ORDER_FAIL: return getPurchaseOrderFail(state, action)
        case actionTypes.STORE_PURCHASE_ORDER_FILE_START: return storePurchaseOrderFileStart(state, action)
        case actionTypes.STORE_PURCHASE_ORDER_FILE_SUCCESS: return storePurchaseOrderFileSuccess(state, action)
        case actionTypes.STORE_PURCHASE_ORDER_FILE_FAIL: return storePurchaseOrderFileFail(state, action)
        case actionTypes.UPLOAD_DOCUMENT_PURCHASE_ORDER_START: return uploadDocumentPurchaseOrderStart(state, action)
        case actionTypes.UPLOAD_DOCUMENT_PURCHASE_ORDER_SUCCESS: return uploadDocumentPurchaseOrderSuccess(state, action)
        case actionTypes.UPLOAD_DOCUMENT_PURCHASE_ORDER_FAIL: return uploadDocumentPurchaseOrderFail(state, action)
        case actionTypes.DELETE_IMAGE_PO_REDUX: return deleteImagePORedux(state,action)
        case actionTypes.DELETE_DOCUMENT_PO_REDUX: return deleteDocumentPORedux(state,action)
        case actionTypes.STORE_PURCHASE_ORDER_RECEIPT_START: return storePurchaseOrderReceiptStart(state, action)
        case actionTypes.STORE_PURCHASE_ORDER_RECEIPT_SUCCESS: return storePurchaseOrderReceiptSuccess(state, action)
        case actionTypes.STORE_PURCHASE_ORDER_RECEIPT_FAIL: return storePurchaseOrderReceiptFail(state, action)
        case actionTypes.CLEAR_FILE_PO_REDUX: return clearFilePoRedux(state, action)
        case actionTypes.DOWNLOAD_PURCHASE_ORDERS_START: return downloadPurchaseOrdersStart(state, action)
        case actionTypes.DOWNLOAD_PURCHASE_ORDERS_SUCCESS: return downloadPurchaseOrdersSuccess(state, action)
        case actionTypes.DOWNLOAD_PURCHASE_ORDERS_FAIL: return downloadPurchaseOrdersFail(state, action)
        case actionTypes.DOWNLOAD_PURCHASE_ORDERS_OLD_START: return downloadPurchaseOrdersOldStart(state, action)
        case actionTypes.DOWNLOAD_PURCHASE_ORDERS_OLD_SUCCESS: return downloadPurchaseOrdersOldSuccess(state, action)
        case actionTypes.DOWNLOAD_PURCHASE_ORDERS_OLD_FAIL: return downloadPurchaseOrdersOldFail(state, action)
        default:
            return state
    }
}

export default reducer;