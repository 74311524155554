import React, {Fragment, useEffect, useState} from "react";
import {Main} from './Styles';
import {
  Grid,
  Typography,
  Paper,
  IconButton,
  InputBase,
  FormControl,
  InputLabel,
  Select,
  Button
} from "@material-ui/core";
import {Add, CalendarToday, Search as SearchIcon} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";

import * as actions from '../../actions';
import {connect} from "react-redux";
import ListPurchaseOrder from "./ListPurchaseOrder";
import {CustomRouterLink, isEmpty} from "../../shared/utility";
import {Loading} from "../../components/UI";

const PurchaseOrderV2 = props => {
  const {onFetchPurchaseOrders, loading, purchaseOrders,onDownloadPurchaseOrders,loadingRounds,rounds,onFetchRounds,onDownloadPurchaseOrdersOld} = props;
  const classes = Main();

  const [formSearch, setFormSearch] = useState({
    status: '',
    search: '',
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),
    round_id: ''
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      onFetchRounds()
      onFetchPurchaseOrders(1, formSearch)
    }, 1000)

    return () => clearTimeout(timer)
  },[onFetchPurchaseOrders, formSearch,onFetchRounds]);

  console.log(rounds);

  let optionRound = (!isEmpty(rounds) ? (
    rounds.map(round => (
      <option value={round.id}>{round.name}</option>
    ))
  ): (
    ''
  ))
  const handleChangePage = (newPage) => {
    onFetchPurchaseOrders(newPage, formSearch);
  }

  const [ startDate, setStartDate ] = useState({
    startDate: new Date(),
  });

  const handleStartDate = (input) => {
    setStartDate(startDate => ({
      ...startDate,
      startDate: input
    }));
    setFormSearch(() => ({
      ...formSearch,
      start_date: moment(input).format('YYYY-MM-DD')
    }));
  };

  const [ endDate, setEndDate ] = useState({
    endDate: new Date(),
  });

  const handleEndDate = (input) => {
    setEndDate(endDate => ({
      ...endDate,
      endDate: input
    }));
    setFormSearch(() => ({
      ...formSearch,
      end_date: moment(input).format('YYYY-MM-DD')
    }));
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEnd, setIsOpenEnd] = useState(false);

  const handleSearch = event => {
    const target = event.target.name;
    event.persist();
    setFormSearch((formSearch) => ({
      ...formSearch,
      [target]: event.target.value
    }));
  };

  const download = () => {
    onDownloadPurchaseOrders(formSearch);
  }

  const downloadOld = () => {
    onDownloadPurchaseOrdersOld(formSearch);
  }

  return (loading || loadingRounds ? <Loading/> :
    <Fragment>
      <div className={classes.root}>
        <div className={classes.row}>
          <Grid
            container
            spacing={2}
            justify={"space-between"}
          >
            <Grid item>
              <Typography variant="h5"><b>Purchase Order EOA Gold</b></Typography>
            </Grid>
            <Grid item>
              {
                (!isEmpty(purchaseOrders)) && (purchaseOrders.status  === true)? (
                <Button variant={"contained"} className={classes.btnAdd} startIcon={React.cloneElement(<Add/>)} component={CustomRouterLink} to={'/purchase-order-v2/create'}>
                  Purchase Order
                </Button>
              ) : (
                <Button variant={"contained"} className={classes.btnAdd} startIcon={React.cloneElement(<Add/>)} disabled>
                  Purchase Order
                </Button>
              )}

            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          <div className={classes.row}>
            <Paper className={classes.paperRules}>
              <Typography variant={'h5'}>
                <b>Peraturan Operasional Purchase Order EOA Gold</b>
              </Typography>
              <div className={classes.ruleContent}>
                1. Jika terjadi kondisi di hari sekarang, ditabel list terdapat status "Menunggu Konfirmasi" dan Status "Pending"<br/>
                2. Jika terjadi kondisi di hari sekarang terdapat status "Pending" == 2<br/>
                3. Jika tidak masuk di jam-jam waktu ronde<br/>
              </div>
            </Paper>
          </div>
        </div>

        <Paper className={classes.paperContent}>
          <Grid
            container
            spacing={2}
          >
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TextField
                  onClick={() => setIsOpen(true)}
                  value={moment(startDate.startDate).format('dddd DD MMMM YYYY')}
                  fullWidth
                  className={classes.margin}
                  label="Tanggal Awal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" disablePointerEvents>
                        <CalendarToday />
                      </InputAdornment>
                    ),
                  }}
                />
                <DatePicker
                  fullWidth
                  open={isOpen}
                  onOpen={() => setIsOpen(true)}
                  onClose={() => setIsOpen(false)}
                  disableFuture
                  // minDate={minDate}
                  variant="outlined"
                  name="start_date"
                  format="dd MMMM yyyy HH:mm"
                  value={startDate.startDate}
                  onChange={e => handleStartDate(e, 'endDate')}
                  TextFieldComponent={() => null}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TextField
                  onClick={() => setIsOpenEnd(true)}
                  value={moment(endDate.endDate).format('dddd DD MMMM YYYY')}
                  fullWidth
                  className={classes.margin}
                  label="Tanggal Akhir"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" disablePointerEvents>
                        <CalendarToday />
                      </InputAdornment>
                    ),
                  }}
                />
                <DatePicker
                  fullWidth
                  open={isOpenEnd}
                  onOpen={() => setIsOpenEnd(true)}
                  onClose={() => setIsOpenEnd(false)}
                  disableFuture
                  // minDate={minDate}
                  variant="outlined"
                  name="end_date"
                  format="dd MMMM yyyy HH:mm"
                  value={endDate.endDate}
                  onChange={handleEndDate}
                  TextFieldComponent={() => null}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xl={3} md={3} sm={12} xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel shrink htmlFor="age-native-label-placeholder">Status</InputLabel>
                <Select
                  fullWidth
                  native
                  defaultValue={formSearch.status}
                  onChange={handleSearch}
                  label="Status"
                  inputProps={{
                    name: 'status',
                    id: 'age-native-label-placeholder'
                  }}
                  name="status"
                  // onChange={handleChange}
                >
                  <option value={''}>Semua</option>
                  <option value={0}>Menunggu Konfirmasi</option>
                  <option value={1}>Sampai</option>
                  <option value={2}>Dikirim</option>
                  <option value={3}>Pending</option>
                  <option value={4}>Diproses</option>
                  <option value={5}>Ditolak</option>
                  <option value={6}>Expired</option>
                  <option value={99}>New Invoice</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel shrink htmlFor="age-native-label-placeholder">Ronde</InputLabel>
                <Select
                  fullWidth
                  native
                  defaultValue={formSearch.round_id}
                  onChange={handleSearch}
                  label="Ronde"
                  inputProps={{
                    name: 'round_id',
                    id: 'age-native-label-placeholder'
                  }}
                  name="round_id"
                  // onChange={handleChange}
                >
                  <option value={''}>Semua</option>
                  {optionRound}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>

        <div className={classes.row}>
          <Grid container justify={"flex-end"}>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <Paper component="form" className={classes.searchRoot}>
                <InputBase
                  className={classes.input}
                  placeholder="Cari Kode Unik Invoice"
                  inputProps={{ 'aria-label': 'Cari Kode Unik Invoice' }}
                  defaultValue={formSearch.search}
                  name={'search'}
                  onChange={handleSearch}
                />
                <IconButton className={classes.iconButton} aria-label="search" disabled>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          <div className={classes.row}>
            <Paper className={classes.paperContent}>

              <div className={classes.paperPadding}>
                <Grid container justify={"space-between"}>
                  <Grid item>
                    <Typography className={classes.tagMenu} variant={"h5"}>History Purchase Order</Typography>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2} justify={"space-around"}>
                      <Grid item>
                        <Button
                          variant={"contained"}
                          size={"medium"}
                          className={classes.btnSuccess}
                          onClick={() => download()}
                        >
                          Download Format 1
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant={"contained"}
                          size={"medium"}
                          className={classes.buttonWarning}
                          onClick={() => downloadOld()}
                        >
                          Download Format 2
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <ListPurchaseOrder purchaseOrders={purchaseOrders} classes={classes} propsHandleChangePage={(newPage) => handleChangePage(newPage)}/>
            </Paper>
          </div>
        </div>
      </div>

    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    purchaseOrders: state.purchaseOrder.purchaseOrdersV2,
    loading: state.purchaseOrder.loading,
    rounds: state.round.rounds,
    loadingRounds: state.round.loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchPurchaseOrders: (page, formSearch) => dispatch(actions.fetchPurchaseOrders(page, formSearch)),
    onDownloadPurchaseOrders: (formSearch) => dispatch(actions.downloadPurchaseOrders(formSearch)),
    onDownloadPurchaseOrdersOld: (formSearch) => dispatch(actions.downloadPurchaseOrdersOld(formSearch)),
    onFetchRounds: () => dispatch(actions.fetchRounds())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderV2);